.rootContainer {
    display: flex;
    height: 100vh;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
}

.boxContainer {
    display: flex;
    min-width: 200px;
    width: 20%;
    max-width: 400px;
    flex-direction: column;
    background-color: #e9e9e9;
    padding: 20px 15px;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.formContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 10px 0px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

h2 {
    font-size: medium;
    margin: 0px;
}

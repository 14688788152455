.itemList {
    margin: 0px 0px;
    padding: 3px;
    background-color: #f1f1F1;
    width: 100%;
    text-align: left;
}

.itemList:hover{
    background-color: indianred;
    cursor: pointer;
    color: white;
}

.disabled-btn{
    background-color: lightgrey;
    border-style: inset;
}

span{
    font-weight: bolder;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: small;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:hover {
  background-color: indianred;
  color: #ffffff;
}
.transition {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; /* Definimos una transición suave */
}

h2,span{
  font-size: small;
}

.sms {
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: flex-start;
}

input{
  font-size: small;
}

button{
  padding: 4px 4px;
}

.car-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map {
  width: 100%;
  height: 100%;
}

.drawer-btn {
  display: none;
}

.search {
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: flex-start;
}

.titleM-r {
  display: none;
}

@media only screen and (max-width: 48em) {
  .sms {
    width: 30%;
  }

  .car-info {
    width: 100%;
    flex-direction: column;
  }

  .map {
    width: 100%;
    margin-left: 0;
  }

  .drawer-btn {
    display: block;
    width: 40px;
    font-size: 1.2rem;
  }

  .search {
    width: 0%;
    display: none;
  }

  .titleM {
    display: none;
  }

  .titleM-r {
    display: block;
  }
}

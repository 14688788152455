body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border-style: none;
  border-radius: 2px;
  width: 100%;
  margin: 0px;
  padding: 4px 2px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 3px;
}

input:focus{
  outline: rgb(190, 84, 65) solid 2px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

button {
  border-radius: 0px;
  border-style: none;
  padding: 5px;
  margin: 0px;
}

button:hover {
  cursor: pointer;
}